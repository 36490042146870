// Card2.js
import React from 'react';
import styles from './Card2.l2.module.css';

function Card2({ leads, selectedColumns }) {
  return (
    <div className={styles.card2}>
      <h2>Leads</h2>
      {Array.isArray(leads) && leads.length > 0 ? (
        <table className={styles.leadsTable}>
          <thead>
            <tr>
              {selectedColumns.map((col) => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, index) => (
              <tr key={index}>
                {selectedColumns.map((col) => (
                  <td key={col}>{lead[col]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No leads available for the selected date range.</p>
      )}
    </div>
  );
}

export default Card2;
