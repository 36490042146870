import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';

function Sidebar() {
  const [showNetworksSubmenu, setShowNetworksSubmenu] = useState(false);
  const [showScheduleSubmenu, setShowScheduleSubmenu] = useState(false);
  const [user, setUser] = useState({ firstName: '', email: '' });

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token'); // Retrieve token from local storage
      if (token) {
        try {
          const response = await axios.get('/api/auth/user-info', {
            headers: { Authorization: `Bearer ${token}` }, // Add token to the request headers
          });
          setUser(response.data); // Set the user details from the API response
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      }
    };

    fetchUserInfo();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    window.location.href = '/login'; // Redirect to the login page
  };

  return (
    <div className="sidebar">
      <div className="user-info-card">
        <div className="user-icon">👤</div>
        <div className="user-details">
          <p>Hi, {user.firstName}</p>
          <p>{user.email}</p>
        </div>
      </div>

      <div className="menu-card">
        <NavLink to="/dashboard" className="menu-button" activeClassName="active">Dashboard</NavLink>

        {/* Networks Section */}
        <div className="menu-item" onClick={() => setShowNetworksSubmenu(!showNetworksSubmenu)}>
          <div className="menu-button">Networks</div>
          {showNetworksSubmenu && (
            <div className="submenu">
              <NavLink to="/networks" className="submenu-button" activeClassName="active">Networks</NavLink>
              <NavLink to="/trackbox" className="submenu-button" activeClassName="active">Trackbox</NavLink>
              <NavLink to="/Networking" className="submenu-button" activeClassName="active">Networking</NavLink>
            </div>
          )}
        </div>

        {/* Schedule Section */}
        <div className="menu-item" onClick={() => setShowScheduleSubmenu(!showScheduleSubmenu)}>
          <div className="menu-button">Schedule</div>
          {showScheduleSubmenu && (
            <div className="submenu">
              <NavLink to="/schedule" className="submenu-button" activeClassName="active">Schedule Leads</NavLink>
              <NavLink to="/schedule/UploadLeads" className="submenu-button" activeClassName="active">UploadLeads</NavLink>
              <NavLink to="/schedule/ScheduledTasks" className="submenu-button" activeClassName="active">Scheduled Tasks</NavLink>
              <NavLink to="/schedule/proxysettings" className="submenu-button" activeClassName="active">Proxy Settings</NavLink>
              <NavLink to="/schedule/user-agent-settings" className="submenu-button" activeClassName="active">User-Agent Settings</NavLink>
            </div>
          )}
        </div>

        <NavLink to="/offers" className="menu-button" activeClassName="active">Offers</NavLink>
        <NavLink to="/leads" className="menu-button" activeClassName="active">Leads</NavLink>
        <NavLink to="/CRM" className="menu-button" activeClassName="active">CRM</NavLink>
        <NavLink to="/ftds" className="menu-button" activeClassName="active">FTDS</NavLink>
      </div>
      
      <div className="logout-card">
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
    </div>
  );
}

export default Sidebar;
